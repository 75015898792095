export class BeException extends Error {

  constructor(message, code, prev) {
    super(message);
    if (Error.captureStackTrace !== undefined) {
      Error.captureStackTrace(this, this.constructor)
    }
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class AuthException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Access denied', code, prev);
  }
}

export class AccessDeniedException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Access denied', code, prev);
  }
}

export class NotFoundException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Resource not found', code, prev);
  }
}

export class BadRequestException extends BeException {

  constructor(message, code, fields, prev) {
    super(message || 'Bad request', code, prev);
  }
}

export class ValidationException extends BadRequestException {

  constructor(message, fields) {
    super(message);
    this.fields = fields;
  }
}

export class UnprocessableEntityException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Unprocessable entity', code, prev);
  }
}

export class ServerErrorException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Internal server error', code, prev);
  }
}

export class LocalException extends Error {

  constructor(message, code, prev) {
    super(message);
    if (Error.captureStackTrace !== undefined) {
      Error.captureStackTrace(this, this.constructor)
    }
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class NetworkErrorException extends LocalException {

  constructor(message, code, prev) {
    super(message || 'Network error', code, prev);
  }
}

export const ACC_TOKEN_MISSING = 1000;
export const ACC_TOKEN_JWT_EXPIRED = 1002;
export const NOT_GRANTED = 1014;
export const UNABLE_SEND_UPLOADED_DOCS_NOTIF = 1047;
export const ORDER_STATUS_INVALID_FOR_SIGNERS_RELOAD = 1048;
export const UNABLE_SEND_NEW_MEETING_PARTICIPANT_NOTIF = 1058;
export const UNABLE_START_ORDER_DOC_PROCESSING = 1064;
export const ORDER_SCHEDULER_MISSING = 1166;
export const UNABLE_SEND_EMAIL_VERIFICATION_MAIL = 1068;
export const UNABLE_SEND_SCHEDULER_ASSIGNED_TO_ORDER_NOTIF = 1114;
export const NOT_ALL_DOCS_RETURNED_BY_NOTARY = 1173;
export const STATEMENT_ALREADY_INVOICED = 1199;
export const STATEMENT_QB_VENDOR_ID_MISSING = 1200;
export const STATEMENT_QB_CUSTOMER_ID_MISSING = 1201;
export const QB_UNABLE_ACQUIRE_TOKEN = 1202;
export const QB_UNABLE_REFRESH_TOKEN = 1203;
export const ORDER_STATUS_INVALID_FOR_START_BIDDING = 1220
export const ORDER_INTERNAL_STATUS_INVALID_FOR_REVIEW_RESOLVE = 1263;
export const ORDER_INTERNAL_STATUS_INVALID_FOR_REVIEW_MARKING = 1269;
export const ORDER_INVALID_STATUS_FOR_RUSH_OPERATIONS = 1262;
export const ORDER_INVALID_STATUS_FOR_VID_OPERATIONS = 1268;
export const ORDER_ACCOUNTING_STATUS_INVALID_FOR_IGNORING_ACCOUNTING = 1272;
export const ORDER_STATUS_INVALID_FOR_IGNORING_ACCOUNTING = 1273;
export const ORDER_FEES_NOT_ALL_SET = 1274;
export const STATEMENT_QB_INVOICE_ITEM_ID_MISSING = 1277;
export const STATEMENT_NOT_CLOSED = 1278;
export const ORDER_ACCOUNTING_STATUS_INVALID_FOR_UNCANCEL = 1282;
export const USER_ACCOUNT_NOT_LOCKED = 1284;
export const CANNOT_DELETE_SINGLE_ORDER_NOTARY = 1285;
export const ORDER_NOTARY_STATUS_INVALID_FOR_REMOVAL = 1286;
export const COUPA_UNABLE_CREATE_INVOICE = 1287;
export const UPLOADED_FILE_IS_ENCRYPTED = 1289;
export const UNABLE_INSERT_DOC_DUE_TO_UNIQUE_KEY = 1290;
export const CANNOT_AWARD_SAME_NOTARY_TO_ORDER = 1296;
export const ORDER_ESIGN_SERVICE_ALREADY_ADDED = 1297;
export const ORDER_CANNOT_REMOVE_ESIGN_SERVICE_WITH_DOCS = 1298;
export const ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN = 1299;
export const ORDER_ESIGN_SERVICE_NOT_ADDED = 1300;
export const ORDER_CANNOT_REMOVE_THE_ONLY_SERVICE = 1301;
export const DEALER_STORE_CANNOT_SWITCH_TO_PAYMENT_PLAN_WITH_SUBSCRIPTION = 1302;
export const DEALER_STORE_CANNOT_UPDATE_PAYMENT_PLAN_WHILE_SETUP_IN_PROGRESS = 1303;
export const STATEMENT_PAYMENT_METHOD_INVALID_FOR_COUPA = 1304;
export const STATEMENT_NOT_SENT_QB = 1305;
export const UNABLE_TO_VERIFY_UPLOADED_FILE = 1309;
export const QB_BILLING_EMAIL_MISSING = 1310;
export const USER_EMAIL_ALERT_NOT_FOUND = 1311;
export const UNABLE_SEND_DEALER_STORE_USAGE_REPORT_NOTIF = 1322;
