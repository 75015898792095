import React, { useEffect, useState } from "react";
import { CardBody, Table, Alert, Button, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import { getOrderQbInfo } from "helpers/backendHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { formatTimestamp, formats } from "helpers/dateHelper";
import PropTypes from "prop-types";
import Order from "model/order";
import OrderNotary from "model/orderNotary";


const QuickbooksTabContainer = ({ ignoreStatement }) => {

  // get redux state from the store
  const { order } = useSelector(state => state.Order.Single);

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const [data, setData] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  // check if ignore button should be visible
  const ignoreStatementVisible = order.status == Order.STATUS_CANCELLED && ![Order.ACCOUNTING_STATUS_SETTLED, Order.ACCOUNTING_STATUS_IGNORED].includes(order.accountingStatus);

  // find active notary, and check status
  const isOrderCompletedByNotary = !!order?.notaries && order.notaries.filter(n => !n.isInactive)[0]?.status === OrderNotary.STATUS_ORDER_COMPLETE;
  const isOrderNotSettled = order?.accountingStatus === Order.ACCOUNTING_STATUS_NOT_SETTLED;

  // one can mark order as ready for billing once and only if the order has been completed by the notary or cancelled
  // and the accounting status is NOT SETLED
  const messageForQbSync = isOrderNotSettled && (isOrderCompletedByNotary || order?.status === Order.STATUS_CANCELLED);

  // do not allow to Ignore statements for cancelled and completed VID orders
  const isIgnoreStatementDisabled = order.vidStatus > 0;

  useEffect(() => {
    refreshQbData();
  }, [order.id]);

  const refreshQbData = () => {
    setIsLoadInProgress(true);
    getOrderQbInfo(order.id)
      .then(response => {
        setData(response);
      })
      .catch(ex => {
        setDataError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }

  return (<CardBody className="px-0">
    {!!data && <React.Fragment>
      <Table className="section-rows table bt-1 view-accounting">
        <tbody>
          {data.hasDealerInfo && <React.Fragment>
            <tr>
              <td className="section-row-label">Invoice Dealership in Qb</td>
              <td className={classnames('section-row-value text-right ps-4')}>{!!data.dealerCreatedTs ? formatTimestamp(data.dealerCreatedTs, formats.US_DATE) : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">QuickBooks Dealer Invoice</td>
              <td className="section-row-value text-right ps-4">{data.dealerInvoiceId ?? '--'}</td>
            </tr>
          </React.Fragment>}
          {data.hasNotaryInfo && <React.Fragment>
            <tr>
              <td className="section-row-label">Notary Bill Entered in Qb</td>
              <td className="section-row-value text-right ps-4">{!!data.notaryCreatedTs ? formatTimestamp(data.notaryCreatedTs, formats.US_DATE) : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Quickbooks Notary Bill</td>
              <td className="section-row-value text-right ps-4">{data.notaryBillId ?? '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Notary Quickbooks Amount</td>
              <td className="section-row-value text-right ps-4">{data.notaryPaymentAmount ?? '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Notary Paid Date</td>
              <td className="section-row-value text-right ps-4">{!!data.notaryPaymentCreatedTs ? formatTimestamp(data.notaryPaymentCreatedTs, formats.US_DATE) : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Notary Paid Check#</td>
              <td className="section-row-value text-right ps-4">{data.notaryPaymentRefId ?? '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Notary Payment has been sent</td>
              <td className="section-row-value text-right ps-4">--</td>
            </tr>
            <tr>
              <td className="section-row-label">Notary notes for accounting</td>
              <td className="section-row-value text-right ps-4">{data.notaryAccountingNotes ?? '--'}</td>
            </tr>
          </React.Fragment>}
        </tbody>
      </Table>
      <div className="text-end mt-4">
        {iAmGranted(perms.edit_order_accounting_status) && ignoreStatementVisible && <React.Fragment>
          <div id="ignore-statement-btn" style={{ display: 'inline-block' }}>
            <Button color="danger" onClick={ignoreStatement} className="mb-2 me-2" id="ignore-statement-btn">
              Ignore <i className="mdi mdi-close ms-1" />
            </Button>
          </div>
        </React.Fragment>}
        {!!messageForQbSync &&
          <Alert color="warning">
            Quickbook information will be displayed here once the statement arives in Quickbooks. Usually the next day after the statement is generated.
          </Alert>}
        {iAmGranted(perms.edit_order_fees) && <Link to={route(routes.view_accounting_edit, { id: order?.id, customValue: 4 })} className="btn btn-primary mb-2">
          Edit <i className="mdi mdi-arrow-right ms-1" />
        </Link>}
      </div>
    </React.Fragment>}
    {isLoadInProgress && <SpinnerChase />}
    {!!dataError && <Alert color="danger" className="fade show text-center mt-3">
      <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load information
    </Alert>}
  </CardBody>)
}

QuickbooksTabContainer.propTypes = {
  ignoreStatement: PropTypes.func,
};

export default QuickbooksTabContainer;